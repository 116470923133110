<template>
	<el-scrollbar class="uu-box">
		<div class="uustore-home">
			<div class="uustore-home-main">
				<div class="uustore-home-warp">
					<div class="uustore-home-head" v-if="userInfo.open_shop && userInfo.open_shop!==2">
						<img class="head-pic" src="@/assets/images/seller/icon-fabulous.png" alt="">
						<div class="head-box">
							<div class="head-name">{{ $t('seller.home.tips') }}</div>
							<div class="head-text">{{ $t('seller.home.subtips') }}</div>
						</div>
						<router-link :to="{ name: 'openShop' }" class="head-link">
							<el-button type="primary" round>{{ $t('seller.home.attestation') }}</el-button>
						</router-link>
					</div>
					<div class="uustore-home-warpmain">
						<div class="uustore-home-wraptab" v-if="userInfo.open_shop && userInfo.open_shop===2">
							<router-link :to="{ name: 'tradePlatform', query: { type: 'pending' }}" class="item">
								<div class="item-num">{{ storeOrder.pending || 0 }}</div>
								<div class="item-name">{{ $t('seller.home.pending') }}</div>
							</router-link>
							<router-link :to="{ name: 'tradePlatform', query: { type: 'accepted' }}" class="item">
								<div class="item-num">{{ storeOrder.shipped || 0 }}</div>
								<div class="item-name">{{ $t('seller.home.accepted') }}</div>
							</router-link>
							<router-link :to="{ name: 'tradePlatform', query: { type: 'shipped' }}" class="item">
								<div class="item-num">{{ storeOrder.evaluated || 0 }}</div>
								<div class="item-name">{{ $t('seller.home.delivered') }}</div>
							</router-link>
							<router-link :to="{ name: 'tradePlatform', query: { type: 'finished' }}" class="item">
								<div class="item-num">{{ storeOrder.after_sales || 0 }}</div>
								<div class="item-name">{{ $t('seller.home.finishOrder') }}</div>
							</router-link>
							<!-- <router-link :to="{ name: 'refundList' }}" class="item">
								<div class="item-num">{{storeOrder.after_sales}}</div>
								<div class="item-name">{{ $t('seller.home.afterSales') }}</div>
							</router-link>
							<router-link :to="{ name: 'tradePlatform', query: { type: 'evaluated' }}" class="item">
								<div class="item-num">{{storeOrder.evaluated}}</div>
								<div class="item-name">{{ $t('seller.home.evaluated') }}</div>
							</router-link>
							<a href="javascript:void(0);" class="item">
								<div class="item-num">{{storeOrder.violation}}</div>
								<div class="item-name">{{ $t('seller.home.violation') }}</div>
							</a> -->
						</div>
						<div class="uustore-home-title">
							<div class="title-name">{{ $t('seller.home.dataTitle') }}</div>
							<div class="title-time"><span>{{ $t('seller.home.datatime') }}</span><span>{{parseInt(Date.now()/1000) | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}</span></div>
						</div>
						<div class="uustore-home-1icpg">
							<ul>
								<li>
									<div class="item">
										<div class="item-name">{{ $t('seller.home.paymentAmount') }}</div>
										<div class="item-num">{{ storeData.today.payment_amount || 0 }}</div>
										<div class="item-old"><span class="mr6">{{ $t('seller.home.yesterday') }}</span><span>{{ storeData.yesterday.payment_amount || 0 }}</span></div>
									</div>
								</li>
								<li>
									<div class="item">
										<div class="item-name">{{ $t('seller.home.visitors') }}</div>
										<div class="item-num">{{ storeData.today.visitors || 0 }}</div>
										<div class="item-old"><span class="mr6">{{ $t('seller.home.yesterday') }}</span><span>{{ storeData.yesterday.visitors || 0 }}</span></div>
									</div>
								</li>
								<li>
									<div class="item">
										<div class="item-name">{{ $t('seller.home.subOrders') }}</div>
										<div class="item-num">{{ storeData.today.sub_orders || 0 }}</div>
										<div class="item-old"><span class="mr6">{{ $t('seller.home.yesterday') }}</span><span>{{ storeData.yesterday.sub_orders || 0 }}</span></div>
									</div>
								</li>
								<li>
									<div class="item">
										<div class="item-name">{{ $t('seller.home.cartsGoods') }}</div>
										<div class="item-num">{{ storeData.today.carts_goods_num || 0 }}</div>
										<div class="item-old"><span class="mr6">{{ $t('seller.home.yesterday') }}</span><span>{{ storeData.yesterday.carts_goods_num || 0 }}</span></div>
									</div>
								</li>
								<li>
									<div class="item">
										<div class="item-name">{{ $t('seller.home.views') }}</div>
										<div class="item-num">{{ storeData.today.views || 0 }}</div>
										<div class="item-old"><span class="mr6">{{ $t('seller.home.yesterday') }}</span><span>{{ storeData.yesterday.views || 0 }}</span></div>
									</div>
								</li>
								<li>
									<div class="item">
										<div class="item-name">{{ $t('seller.home.cartsNumber') }}</div>
										<div class="item-num">{{ storeData.today.carts_num || 0 }}</div>
										<div class="item-old"><span class="mr6">{{ $t('seller.home.yesterday') }}</span><span>{{ storeData.yesterday.carts_num || 0 }}</span></div>
									</div>
								</li>
								<li>
									<div class="item">
										<div class="item-name">{{ $t('seller.home.unitPrice') }}</div>
										<div class="item-num">{{ storeData.today.customer_unit_price || 0 }}</div>
										<div class="item-old"><span class="mr6">{{ $t('seller.home.yesterday') }}</span><span>{{ storeData.yesterday.customer_unit_price || 0 }}</span></div>
									</div>
								</li>
								<li>
									<div class="item">
										<div class="item-name">{{ $t('seller.home.buyers') }}</div>
										<div class="item-num">{{ storeData.today.buyers || 0 }}</div>
										<div class="item-old"><span class="mr6">{{ $t('seller.home.yesterday') }}</span><span>{{ storeData.yesterday.buyers || 0 }}</span></div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="uustore-home-bar">
					<div class="uustore-home-barbox">
						<div class="barbox-user">
							<img :src="storeInfo.store_logo?storeInfo.store_logo:$imageErrorLogo" class="barbox-user-pic" @error="imageLoadError" alt="">
							<div class="barbox-user-name"><span>{{storeInfo.store_name}}</span></div>
						</div>
						<div class="barbox-action">
							<el-button size="small" @click="goShop()" round><i class="el-icon-s-shop"></i>{{ $t('seller.home.into') }}</el-button>
							<!-- <el-button>{{ $t('seller.home.alipay') }}</el-button> -->
						</div>
						<div class="barbox-main">
							<!-- <div class="item">
								<div class="item-label">{{ $t('seller.home.credit') }}</div>
								<div class="item-main"><span class="item-text">{{ $t('seller.home.points') }}</span></div>
							</div>
							<div class="item">
								<div class="item-label">{{ $t('seller.home.score') }}</div>
								<div class="item-main">
									<div class="item-cell">
										<div class="item-cell-title">{{ $t('seller.home.describe') }}</div>
										<div class="item-cell-value">0.0</div>
										<span class="item-cell-text">-</span>
									</div>
									<div class="item-cell">
										<div class="item-cell-title">{{ $t('seller.home.service') }}</div>
										<div class="item-cell-value">0.0</div>
										<span class="item-cell-text">-</span>
									</div>
									<div class="item-cell">
										<div class="item-cell-title">{{ $t('seller.home.logistics') }}</div>
										<div class="item-cell-value">0.0</div>
										<span class="item-cell-text">-</span>
									</div>
								</div>
							</div> -->
							<div class="item">
								<div class="item-label">{{ $t('seller.home.deposit') }}</div>
								<div class="item-main"><el-tag type="success" effect="dark" size="small">{{ $t('seller.home.isPay') }}</el-tag></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { getInfo, statisticsShop, statisticsOrder } from '@/api/seller/index'
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			return {
				storeInfo: {},
				storeData: {
					today: {},
					yesterday: {}
				},
				storeOrder: {},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async getData(){
				const storeInfo = await getInfo({ uuid: this.$store.getters.uuid })
				this.storeInfo = storeInfo.data

				const storeData = await statisticsShop({ uuid: this.$store.getters.uuid })
				this.storeData = storeData.data

				const storeOrder = await statisticsOrder({ uuid: this.$store.getters.uuid })
				this.storeOrder = storeOrder.data
			},
			imageLoadError(e){
				e.target.src = this.$imageErrorLogo;
				e.target.style.objectFit = 'fill';
			},
			goShop(){
				if(this.storeInfo.store_id>0){
					window.open(this.$itemBasePath+'/m/store/info/'+this.storeInfo.store_id);
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	.uustore-home {
		padding: 28px 60px 36px;
		.uustore-home-main {
			max-width: 1692px;
			margin: 0 auto;
			position: relative;
			display: flex;
			.uustore-home-warp {
				display: flex;
				flex: 1;
				margin-right: 50px;
				flex-direction: column;
				.uustore-home-head {
					display: flex;
					align-items: center;
					height: 110px;
					padding: 0 60px 0 14px;
					border-radius: 12px;
					background-color: #fffaf0;
					box-sizing: border-box;
					margin-bottom: 24px;
					.head-pic {
						width: 74px;
						height: 75px;
						margin-right: 18px;
						object-fit: contain;
					}
					.head-name {
						color: #333333;
						font-size: 16px;
					}
					.head-text {
						font-size: 12px;
						margin-top: 8px;
						color: #666666;
					}
					.head-link {
						margin-left: auto;
					}
				}
				
				.uustore-home-wraptab {
					display: flex;
					margin-bottom: 40px;
					border-radius: 12px;
					overflow: hidden;
					height: 96px;
					background-color: $--seller-primary-light-1;
					.item {
						flex: 1;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						.item-num {
							color: #FFFFFF;
							font-size: 30px;
							margin-bottom: 6px;
							max-width: 95%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						.item-name {
							opacity: .6;
							font-size: 14px;
							color: #FFFFFF;
						}
					}
					.item:hover {
						background-color: $--seller-primary-light-2;
						.item-name {
							opacity: 1;
						}
					}
				}
				
				.uustore-home-title {
					height: 24px;
					line-height: 24px;
					margin-bottom: 18px;
					.title-name {
						float: left;
						font-size: 18px;
						margin-right: 12px;
						color: #333333;
					}
					.title-time {
						float: left;
						font-size: 12px;
						color: #999999;
						span {
							margin-right: 4px;
						}
					}
				}
				
				.uustore-home-1icpg {
					overflow: hidden;
					margin-left: -12px;
					margin-right: -12px;
					li {
						float: left;
						padding: 0 12px;
						width: 25%;
						box-sizing: border-box;
					}
					.item {
						height: 90px;
						border-radius: 12px;
						background-color: #f7f8fa;
						padding: 12px 18px;
						margin-bottom: 18px;
						cursor: pointer;
						box-sizing: border-box;
						.item-name,.item-old {
							font-size: 12px;
							color: #666666;
							line-height: 1;
						}
						.mr6 {
							margin-right: 6px;
						}
						.item-num {
							font-size: 28px;
							line-height: 1;
							margin: 6px 0 7px;
							color: #111111;
						}
					}
				}
			}
			
			.uustore-home-bar {
				display: flex;
				width: 357px;
				flex-direction: column;
				
				.uustore-home-barbox {
					cursor: pointer;
					padding: 30px 26px 34px;
					border-radius: 12px;
					margin-bottom: 40px;
					background: linear-gradient(180deg,#f5f8ff,#fff);
					box-shadow: inset 0 1px 3px 0 hsla(0,0%,100%,.5);
					.barbox-user {
						display: flex;
						.barbox-user-pic {
							flex-shrink: 0;
							width: 48px;
							height: 48px;
							border-radius: 50%;
							overflow: hidden;
							margin-right: 12px;
							box-shadow: 0 17px 29px 0 rgba(51,187,241,.15);
						}
						.barbox-user-name {
							flex: 1;
							display: flex;
							flex-direction: column;
							justify-content: space-around;
							font-size: 22px;
							color: #111111;
						}
					}
					.barbox-action {
						text-align: center;
						margin: 15px 0 24px;
						.el-icon-s-shop {
							font-size: 14px;
						}
					}
					.barbox-main {
						.item {
							margin-bottom: 15px;
							display: flex;
							width: 100%;
							align-items: center;
							.item-label {
								flex-shrink: 0;
								font-size: 14px;
								color: #111111;
							}
							.item-main {
								flex: 1;
								display: flex;
								text-align: right;
								justify-content: flex-end;
								.item-cell {
									flex: 1;
									display: flex;
									justify-content: flex-end;
									.item-cell-title {
										font-size: 12px;
										color: rgba(51,51,51,.6);
										margin-right: 4px;
									}
									.item-cell-value {
										font-size: 16px;
										margin-right: 4px;
										color: #111111;
									}
									.item-cell-text {
										font-size: 14px;
										color: #999999;
									}
								}
								.el-tag {
									border-radius: 12px;
								}
							}
							.item-text {
								color: #999999;
								font-size: 12px;
								color: rgba(51,51,51,.6);
							}
						}
					}
				}
			}
		}
	}
	
	@media (min-width: 1856px) {
		.uustore-home-1icpg li {
			width: 16.66%;
		}
	}
</style>
